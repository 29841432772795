<script setup>
import { Link } from '@inertiajs/vue3';
import { inject } from 'vue';

// Components
import TinyRating from '@/Components/Flexer/Partials/TinyRating.vue';
import Icon from '@/Components/Icon.vue';
import NameAndLike from '../Flexer/Partials/NameAndLike.vue';
import Bold from './Cells/Bold.vue';
import Cell from './Cells/Cell.vue';
import Cta from './Cells/Cta.vue';
import Email from './Cells/Email.vue';
import FlexerCard from './Cells/FlexerCard.vue';
import Tel from './Cells/Tel.vue';
import TableLinkWithPopup from './TableLinkWithPopup.vue';

// Icons
import CheckMark from '@i/checkmark-colourable.svg?raw';
import Cross from '@i/cross-colourable.svg?raw';
import Download from '@i/download-current.svg?raw';
import Arrow from '@i/pagination-arrow-current.svg?raw';

// Variables
const route = inject('route');

const props = defineProps({
    data: {},
    headers: {},
    loading: {},
    linkOpensPopup: Boolean,
    hideNoResults: Boolean,
});

const emits = defineEmits(['buttonClicked', 'rowClicked', 'cellClicked']);
</script>

<template>
    <template v-if="data.length !== 0">
        <tbody :class="{ 'opacity-30': loading }" class="transition-opacity duration-300">
            <tr
                v-for="({ id, rowData, colorCode, inactive }, i) in data"
                :key="id || i"
                class="relative text-gray-800 transition-colors duration-300 bg-white"
                @click="emits('rowClicked', { row: i, id: id })"
                :class="{
                    'grayscale bg-gray-lighter/50 cursor-not-allowed opacity-50': inactive,
                }"
            >
                <td
                    class="border-b-[1px] border-blue/30 my-2 text-blue relative"
                    :class="{
                        hidden: headers[j].from,
                        'md:table-cell': headers[j].from == 'md',
                        'lg:table-cell': headers[j].from == 'lg',
                    }"
                    v-for="(cellData, j) in rowData"
                    :key="j"
                    @click="emits('cellClicked', { row: i, column: j, id: id, cell: headers[j].cell })"
                >
                    <span
                        v-if="colorCode && j == 0"
                        class="absolute w-1 top-2 bottom-2 rounded-2"
                        :style="{ background: colorCode }"
                    ></span>
                    <template v-if="typeof headers[j].cell === 'undefined'">
                        <Cell> {{ cellData }} </Cell>
                    </template>
                    <template v-else-if="headers[j].cell == 'Bold'">
                        <Bold :data="cellData" />
                    </template>
                    <template v-else-if="headers[j].cell == 'Email'">
                        <Email :data="cellData" />
                    </template>
                    <template v-else-if="headers[j].cell == 'Tel'">
                        <Tel :data="cellData" />
                    </template>
                    <template v-else-if="headers[j].cell === 'Rating'">
                        <div class="mx-4">
                            <TinyRating :value="cellData[0]" :reviewCount="cellData[1]" />
                        </div>
                    </template>
                    <template v-else-if="headers[j].cell === 'Download'">
                        <a :href="cellData">
                            <Icon class="p-6 -m-2 duration-300 cursor-pointer hover:text-pink" :icon="Download" />
                        </a>
                    </template>
                    <template v-else-if="headers[j].cell === 'LinkWithPopup'">
                        <TableLinkWithPopup :linkOpensPopup="linkOpensPopup" />
                    </template>
                    <template v-else-if="headers[j].cell === 'NameAndLike'">
                        <NameAndLike
                            :name="cellData[0]"
                            :isBold="cellData[1]"
                            :FlexerID="cellData[2]"
                            :liked="cellData[3] ? cellData[3] : false"
                        />
                    </template>
                    <template v-else-if="headers[j].cell === 'Cta'">
                        <Cta :application="cellData[0]" :shift="cellData[1]" />
                    </template>
                    <template v-else-if="headers[j].cell === 'Remove'">
                        <div class="flex items-center justify-center w-full">
                            <button
                                v-if="cellData"
                                type="button"
                                class="transition-opacity duration-300 hover:opacity-50"
                            >
                                <img src="/icon/remove_red.svg" alt="X" />
                            </button>
                        </div>
                    </template>
                    <template v-else-if="headers[j].cell === 'Arrow'">
                        <Link v-if="cellData" :href="cellData">
                            <Icon
                                class="float-right h-auto p-6 -m-2 duration-300 cursor-pointer hover:text-pink"
                                :icon="Arrow"
                            />
                        </Link>
                        <Icon
                            v-else
                            class="float-right h-auto p-6 -m-2 duration-300 cursor-pointer hover:text-pink"
                            :icon="Arrow"
                        />
                    </template>
                    <template v-else-if="headers[j].cell === 'FakeArrow'">
                        <Icon
                            class="float-right h-auto p-6 -m-2 duration-300 cursor-pointer hover:text-pink"
                            :icon="Arrow"
                        />
                    </template>
                    <template v-else-if="headers[j].cell === 'FlexerCard'">
                        <FlexerCard :flexer="cellData" />
                    </template>
                    <template v-else-if="headers[j].cell === 'Boolean'">
                        <Cell>
                            <Icon v-if="cellData" class="float-right h-auto p-6 -m-2 text-green" :icon="CheckMark" />
                            <Icon v-else class="float-right h-auto p-6 -m-2 text-red" :icon="Cross" />
                        </Cell>
                    </template>
                    <template v-else-if="headers[j].cell === 'CustomHtml'">
                        <Cell>
                            <div v-html="cellData"></div>
                        </Cell>
                    </template>
                    <template v-else>
                        <div class="flex items-center px-4 py-2 font-bold min-h-10 text-pink">
                            {{ headers[j].cell }} cell is unknown
                        </div>
                    </template>
                </td>
            </tr>
        </tbody>
    </template>
    <tbody v-else-if="!hideNoResults">
        <tr>
            <td class="flex items-center w-full px-4 py-4 font-bold min-h-10 whitespace-nowrap">
                {{ $t('No results found') }}
            </td>
        </tr>
    </tbody>
</template>
