<script setup>
import { inject } from 'vue';

// Components
import Toggle from '@/Components/Button/Toggle.vue';
import Media from '@/Components/Media.vue';

// Icons
import star from '@i/star-icon-active.svg';
import unstar from '@i/star-icon-inactive.svg';

const route = inject('route');
defineProps({
    flexer: Object,
});
</script>

<template>
    <div class="flex items-center gap-2 px-4 py-2">
        <Media classes="object-cover w-10 h-10 rounded-full" :media="flexer.media[0]" />
        <span class="font-bold">{{ flexer.display_name }}</span>

        <Toggle
            :active="flexer.is_liked"
            :icon="star"
            activateMethod="put"
            :activateUrl="route('location.liked-flexers.update', { liked_flexer: flexer.id })"
            deactivateMethod="delete"
            :deactivateUrl="route('location.liked-flexers.destroy', { liked_flexer: flexer.id })"
            :activeIcon="star"
            :inactiveIcon="unstar"
        />
    </div>
</template>
