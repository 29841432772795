<script setup>
import { inject } from 'vue';

// Components
import Toggle from '@/Components/Button/Toggle.vue';

// Icons
import star from '@i/star-icon-active.svg';
import Unstar from '@i/star-icon-inactive.svg';

const route = inject('route');
const props = defineProps({
    name: String,
    isBold: String,
    FlexerID: String,
    liked: Boolean,
});
</script>

<template>
    <div class="flex items-center gap-2 px-4 py-2">
        <span :class="{ 'font-bold': isBold === 'bold' }">{{ name }}</span>

        <Toggle
            iconBtn
            :active="liked"
            :icon="star"
            activateMethod="put"
            :activateUrl="route('location.liked-flexers.update', { liked_flexer: FlexerID })"
            deactivateMethod="delete"
            :deactivateUrl="route('location.liked-flexers.destroy', { liked_flexer: FlexerID })"
            :activeIcon="star"
            :inactiveIcon="Unstar"
        />
    </div>
</template>