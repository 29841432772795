<script setup>
import ButtonSmall from '@/Components/Button/Small.vue';
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
    application: Object,
    shift: Object,
});

const shiftHasStarted = computed(() => {
    let start = new Date(props.shift.start),
        now = new Date();

    return start <= now;
});

const isEndStatus = computed(() => {
    return (
        props.application.status === 'accepted' ||
        props.application.status === 'retracted' ||
        props.application.status === 'rejected' ||
        props.application.status === 'acceptance_lapsed' ||
        props.application.status === 'filled_by_others'
    );
});

const actionAllowed = computed(() => {
    if (usePage().props.user.type === 'staff') {
        return false;
    }

    return (
        props.shift.status === 'open' &&
        !shiftHasStarted.value &&
        !isEndStatus.value &&
        props.application.status !== 'invited' &&
        props.application.status !== 'acceptance_lapsed' &&
        props.application.status !== 'accepted'
    );
});

const extraClasses = computed(() => {
    if (props.application.status === 'acceptance_lapsed') {
        return 'text-orange';
    } else if (props.application.status === 'accepted') {
        return 'text-green';
    } else {
        return '';
    }
});

const outputString = computed(() => {
    switch (props.application.status) {
    case 'lapsed':
        return t('Lapsed');
    case 'retracted':
        return t('Retracted');
    case 'acceptance_lapsed':
        return `${t('Lapsed')}\n${t('Previously accepted')}`;
    case 'confirmation_required':
        return t('Invite');
    case 'invited':
        return t('Invited');
    case 'applied':
        return usePage().props.user.type === 'staff' ? null : t('Accept');
    case 'filled_by_others':
        return t('Filled by others');
    case 'no_show':
        return t('No show');
    case 'absent':
        return t('Absent');
    case 'illness':
        return t('Illness');
    default:
        return t(props.application.status);
    }
});
</script>

<template>
    <ButtonSmall v-if="actionAllowed && !isEndStatus" class="self-center max-w-35">
        {{ outputString }}
    </ButtonSmall>

    <div v-else class="text-[10px] font-semibold text-center uppercase" :class="extraClasses">
        {{ outputString }}
    </div>
</template>
