
<script setup>
const props = defineProps({
    headers: {},
    orderBy: {
        type: String,
        default: '',
    },
    orderDir: {
        type: String,
        default: '',
    },
    simpleLayout: Boolean,
});

const emit = defineEmits(['update:orderBy', 'update:orderDir']);

const emitUpdates = (header) => {
    if (header.orderBy == props.orderBy) {
        emit('update:orderDir', props.orderDir == 'asc' ? 'desc' : 'asc');
    } else {
        emit('update:orderDir', 'asc');
        emit('update:orderBy', header.orderBy);
    }
};
</script>

 <template>
    <thead
        class="h-[56px] text-blue"
        :class="{ 'bg-gray-lighter': !simpleLayout, 'border-b-[1px] border-blue/30 ': simpleLayout }"
    >
        <tr class="select-none">
            <template v-for="header in headers" :key="header">
                <th
                    :style="{ width: header.width }"
                    v-if="typeof header == 'string'"
                    class="sticky top-0 px-4 py-3 font-bold text-left"
                ></th>
                <th
                    v-else-if="typeof header == 'object' && header.orderBy"
                    @click="emitUpdates(header)"
                    :style="{ width: header.width }"
                    :class="{
                        hidden: header.from,
                        'md:table-cell': header.from == 'md',
                        'lg:table-cell': header.from == 'lg',
                    }"
                    class="sticky top-0 font-bold text-left cursor-pointer"
                >
                    <div class="flex items-center gap-2 px-4 py-3">
                        {{ header.label }}

                        <img
                            class="inline-block w-[13px] h-auto"
                            :class="{
                                'rotate-0': orderDir != 'asc',
                                'rotate-180': orderDir == 'asc',
                            }"
                            v-if="header.orderBy == orderBy"
                            src="@/../img/icon/blue-dropdown-arrow-down.svg"
                        />
                        <img
                            class="inline-block w-[13px] h-auto opacity-20"
                            v-if="header.orderBy !== orderBy"
                            src="@/../img/icon/blue-dropdown-arrow-down.svg"
                        />
                    </div>
                </th>
                <th
                    :style="{ width: header.width }"
                    v-else-if="typeof header == 'object'"
                    :class="{
                        hidden: header.from,
                        'md:table-cell': header.from == 'md',
                        'lg:table-cell': header.from == 'lg',
                        'pl-0': simpleLayout,
                    }"
                    class="sticky top-0 px-4 py-3 font-bold text-left min-w-10"
                >
                    {{ header.label }}
                </th>
            </template>
        </tr>
    </thead>
</template>
