<script setup>
import tHead from '@/Components/Table/tHead.vue';
import tBody from './tBody.vue';

const props = defineProps({
    loading: Boolean,
    orderBy: { type: String, default: '' },
    orderDir: { type: String, default: '' },
    tableSettings: { type: Object, required: true },
    data: {},
    footer: {},
    footerFaded: {},
    simpleLayout: Boolean,
    linkOpensPopup: Boolean,
    hideNoResults: Boolean,
});

const emits = defineEmits(['update:orderBy', 'update:orderDir', 'buttonClicked', 'rowClicked', 'cellClicked']);

const rowClicked = ({ row, id }) => emits('rowClicked', { row, id });
const cellClicked = ({ row, column, id, cell }) => emits('cellClicked', { row, column, id, cell });
const orderByUpdated = (newOrderBy) => emits('update:orderBy', newOrderBy);
const orderDirUpdated = (newOrderDir) => emits('update:orderDir', newOrderDir);
</script>

<template>
    <table
        cellspacing="0"
        cellpadding="0"
        style="border: none"
        class="w-full overflow-hidden text-base leading-tight bg-white border-separate rounded text-blue"
    >
        <tHead
            :simpleLayout="simpleLayout"
            :headers="tableSettings"
            :orderBy="orderBy"
            @update:orderBy="orderByUpdated"
            :orderDir="orderDir"
            @update:orderDir="orderDirUpdated"
        />
        <tBody
            :headers="tableSettings"
            :data="data"
            :loading="loading"
            :linkOpensPopup="linkOpensPopup"
            :hideNoResults="hideNoResults"
            @row-clicked="rowClicked"
            @cell-clicked="cellClicked"
        />
        <slot name="extraTableBody"></slot>
    </table>
</template>
